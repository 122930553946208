import React from 'react';
import * as styles from './styles.css';
import { Card } from '@/features/carousel/model';
import { Badge } from '../Badge';
import Image from 'next/image';
import { Button } from '../Button';
import { Body, Label } from '../Typography';
import classNames from 'classnames';
import { Icon } from '@/components/Icon';
import { ETabIndexesOrderingLevels } from '@/root/types/types';
interface PortraitCardProps {
  background: string;
  badge: {
    label: string;
    backgroundColor: string;
    labelColor: string;
  } | null;
  title: string;
  description: string;
  hasOverlay?: boolean;
  buttonText?: string;
  icon?: string;
}
export const PortraitCard: React.FC<PortraitCardProps> = ({
  icon = 'play',
  ...props
}) => {
  const backgroundImage = props.background;

  // GET THIS VALUE FROM arenaData.arena_theme later
  const overlayColor = 'rgba(0, 0, 0, 0.7)';
  return <div className={classNames(styles.card, !props.hasOverlay && styles.noOverlay)} role='button' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION} data-sentry-component="PortraitCard" data-sentry-source-file="index.tsx">
			{backgroundImage && <Image className={styles.image} src={backgroundImage} alt={props.title} loading='lazy' fill priority={false} />}
			{/* TODO: Change this to use badge properties */}
			{props.badge && props.badge.label && props.badge.backgroundColor && <Badge backgroundColor={props.badge.backgroundColor} labelColor={props.badge.labelColor} label={props.badge.label} />}
			{props.hasOverlay && <div className={styles.overlayWrapper}>
					<div className={styles.overlay} style={{
        backgroundColor: overlayColor
      }}>
						<div className={styles.wrapper}>
							<Body className={styles.title} size='large'>
								{props.title}
							</Body>
							<Body className={styles.description} size='small'>
								{props.description}
							</Body>
						</div>
						<Button focusable={false} variant='filled' size='medium' className={styles.button}>
							<Icon name={icon} />
							<Label size='large'>{props.buttonText || 'Play Now'}</Label>
						</Button>
					</div>
				</div>}
		</div>;
};