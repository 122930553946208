import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2FStandardCard%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA71Vy26jMBTd5yusSiO1C0dAkiZxN%2FMnlbEvjVtjI9sEOqP8%2Bwg7ECBpoa00q8T4nPs49%2BHlcxyzKFZxhP4uECq0FU5oRZABSZ04wtMCoVQbDgYbykVpCTpSc4%2Fxc%2Bxe%2BbbOEvvQYFhprDYEFVooBybQamzFH6FeSGsi1fXT4rRYtl5j77US3B0IiqPoV8M7gHg5uMtZp6%2FAHM6EI4jpY2t8Kqaem2SUHE2tlqWbmVzP0Kofb7LbFfUg4E1SDPNbD%2FDbZAq%2FIYRmDoynMa0cKEfQ3d3Th%2BELZcERFM2tU8AQFC2TjYEcWS0FH0Ejj9QFZcK9n21f1XcQ9aEpSz%2F2jhyPkJlmpZ2BfPSXV61wBJNJXRF0EJyD%2BnIBt5%2B0%2BdiZhKxV1oQb%2Fz%2Bn5kWoVnLK3rjRBc6EdI2uqSzN%2FaaovYQF5dy3%2FyiofRgZLRtFq4MIpXSGqjauVDuncxQtVzbk2BwJwtctEwXx0SjFlhGNwF7%2FT8FSzADRGZjubufvMq1csw9gLEe89nJIoQC3NRghgpzeQnUbEXsEF7aQ9J0gXEH6JlxYOKg7eh9M0rzw%2FYb6OKyN8ON2BOMEo9LXBGqHL10HUorCCnu7F%2FtCoy9l32PuJ%2BCrSbGqL0uR%2FCcp5uTWY1IP7xLJJPgMml%2FMhQEWZoVpWeZ%2BFbyW1onsHXeb0xaUAU7BVRCWxa0FMpz7nv90sIPWUdjen%2B%2Bb8uGj5L9v7XcOXFBkmQFQiCqO7nNa4%2B7ZTNZF%2FeCtL4fP%2BeVp3T4Gd5cA9ucvpz5tNaI97sa0XXSDtv6et92ZFjYqbpfHpiVPDcGcMTgNVbnyPeVjwN9PkpIfBzbXx0f89MzvxkZpBVN6tiZP%2FwBwEbaNEAoAAA%3D%3D%22%7D"
export var button = '_11c01n1b';
export var card = '_11c01n10';
export var description = '_11c01n19';
export var fullImage = '_11c01n11';
export var iconImage = '_11c01n12';
export var medium = '_11c01n14';
export var noOverlay = '_11c01n15';
export var overlay = '_11c01n17';
export var overlayWrapper = '_11c01n16';
export var small = '_11c01n13';
export var title = '_11c01n18';
export var wrapper = '_11c01n1a';