'use client';

import styles from './styles.module.css';
import { useEffect, useRef, useState, useLayoutEffect, useCallback, useContext } from 'react';
import { StandardCard } from '../StandardCard';
import { useUnit } from 'effector-react';
import { $arenaDataStore } from '@/app.model';
import { ShortGameModelFromFeed } from '@/types/games';
import { Badge } from '@/libs/api/arena-data';
import { getValidBadgesBySlug } from '@/libs/utils/utils';
import { $displayAdsEmpty, $isCmpAndAdsDisabledByRegion, AdOptions, AdTypes, getDimensions } from '@/features/ad/model';
import { EScreenSize, TScreen } from '@/libs/utils/screensize';
import Ad from '@/features/ad/view';
import classNames from 'classnames';
import { LocalizedLink } from '../Link';
import { ETabIndexesOrderingLevels } from '@/root/types/types';
import { AnalyticsContext } from '../Layout';
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
const MAX_VISIBLE_GAMES = 36,
  DEFAULT_ROW_TAKE = 3,
  SCROLLING_ROW_TAKE = 6;
type TCardElement = ShortGameModelFromFeed & {
  type?: 'card';
};
type TAdElement = {
  type: 'ad';
  adType: AdTypes;
};
type TPageData = Array<TCardElement | TAdElement>;
type TPage = Array<{
  page: number;
  data: TPageData;
}>;
export const StandardCardsGrid = ({
  title,
  category,
  rootCategorySlug,
  games,
  arenaBadges,
  screenSize,
  isSearchPage,
  isPending,
  adsDivider,
  adsInterval
}: {
  title?: string;
  category?: string;
  rootCategorySlug?: string;
  games: ShortGameModelFromFeed[];
  arenaBadges?: Badge[];
  screenSize?: keyof TScreen | null;
  isSearchPage?: boolean;
  isPending?: boolean;
  adsDivider: boolean;
  adsInterval: number;
}) => {
  const arenaData = useUnit($arenaDataStore);
  const displayAdsEmpty = useUnit($displayAdsEmpty);
  const isAdsDisabled = useUnit($isCmpAndAdsDisabledByRegion);
  const [page, setPage] = useState(1);
  const [atLastPage, setAtLastPage] = useState(false);
  const gridRef = useRef<HTMLDivElement>(null);
  const sentinelRef = useRef<HTMLDivElement>(null);
  const [columnsCount, setColumnsCount] = useState(0);
  const [dividedElements, setDividedElements] = useState<TPage>([]);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [INIT_ROW_TAKE] = useState(SCROLLING_ROW_TAKE);
  const [currentRow, setCurrentRow] = useState(INIT_ROW_TAKE);
  const {
    AITracks
  } = useContext(AnalyticsContext);
  const mobileAdScreens = [EScreenSize.xs, EScreenSize.sm];
  const calculateGrid = useCallback(() => {
    if (!gridRef.current) return;
    const gridWidth = gridRef.current.offsetWidth;
    const cardWidth = getComputedStyle(gridRef.current).getPropertyValue('--card-width').trim();
    const bodyFontSizeValue = parseFloat(getComputedStyle(document.body).fontSize);
    const isCardWidthPercent = cardWidth.endsWith('%');
    const cardWidthPx = parseInt(cardWidth) * bodyFontSizeValue;
    const childWidth = cardWidthPx;
    const computedStyle = window.getComputedStyle(gridRef.current);
    const gap = parseFloat(computedStyle.gap) || 0;
    const columns = isCardWidthPercent ? 2 : Math.floor((gridWidth + gap) / (childWidth + gap));
    setColumnsCount(columns);
  }, []);
  const loadMore = useCallback((reset = false) => {
    if (reset) return;
    setPage(prevPage => prevPage + 1);
  }, []);
  useLayoutEffect(() => {
    calculateGrid();
    const resizeObserver = new ResizeObserver(calculateGrid);
    gridRef.current && resizeObserver.observe(gridRef.current);
    return () => resizeObserver.disconnect();
  }, [calculateGrid]);
  useEffect(() => {
    if (dividedElements.length === page) setAtLastPage(true);
  }, [page, dividedElements]);
  useEffect(() => {
    const sentinel = sentinelRef.current;
    if (!rowsTotal || !sentinel) return;
    const observer = new IntersectionObserver(entries => {
      if (currentRow === rowsTotal) {
        observer.disconnect();
        return;
      }
      if (entries[0]?.isIntersecting) {
        loadMore();
      }
    }, {
      root: null,
      threshold: 1,
      rootMargin: '40px'
    }) //half of the card
    ;
    observer.observe(sentinel as HTMLDivElement);
    return () => observer.disconnect();
  }, [rowsTotal, currentRow, loadMore]);
  useEffect(() => {
    if (rowsTotal) {
      const nextRow = Math.min(page * INIT_ROW_TAKE, rowsTotal);
      setCurrentRow(nextRow);
    }
  }, [page, INIT_ROW_TAKE, rowsTotal]);
  useEffect(() => {
    //init
    if (columnsCount && !rowsTotal && isAdsDisabled !== null) {
      setRowsTotal(Math.ceil(games.length / columnsCount));
    }
  }, [columnsCount, games, rowsTotal, isAdsDisabled]);
  useEffect(() => {
    if (!columnsCount) {
      return;
    }
    const interval = adsInterval;
    const isEnabled = adsDivider;
    const gamesPerPage = columnsCount * INIT_ROW_TAKE;
    let pageCounter = 1;
    let currentPage: {
      page: number;
      data: Array<TCardElement | TAdElement>;
    } = {
      page: pageCounter,
      data: []
    };
    let gamesInCurrentPage = 0; // Track only the number of games, excluding ads
    let totalGamesCount = 0; // Total count of games across all pages (ignores ads)

    const paginatedData = games.reduce((acc: TPage, game, index) => {
      currentPage.data.push(game);
      gamesInCurrentPage++; // Increment count for games in the current page
      totalGamesCount++; // Increment total count of games across all pages

      // Insert ads globally based on totalGamesCount, not just the current page
      const insertAdIndex = columnsCount * interval;
      if (isEnabled && totalGamesCount % insertAdIndex === 0 && index !== games.length - 1 && interval && !isAdsDisabled && !isSearchPage) {
        currentPage.data.push({
          type: 'ad',
          adType: mobileAdScreens.includes(screenSize as EScreenSize) ? AdTypes.AD_300x250 : AdTypes.AD_728x90
        });
      }

      // If the current page has reached the required number of games (3 rows), finalize the page and start a new one
      if (gamesInCurrentPage >= gamesPerPage) {
        acc.push(currentPage);
        pageCounter++;
        currentPage = {
          page: pageCounter,
          data: []
        };
        gamesInCurrentPage = 0; // Reset game count for the new page
      }
      return acc;
    }, []);

    // Push any remaining games that didn't fill the last page
    if (currentPage.data.length > 0) {
      paginatedData.push(currentPage);
    }
    setDividedElements(paginatedData);
  }, [games, screenSize, columnsCount, INIT_ROW_TAKE, adsInterval, isAdsDisabled, isSearchPage]);
  function isCardElement(element: TCardElement | TAdElement): element is TCardElement {
    return element.type !== 'ad';
  }
  const renderPage = (data: Array<TCardElement | TAdElement>) => {
    return data.map((el, index) => {
      const gameAliasOrSlug = isCardElement(el) ? el.meta.alias || el.slug : '';
      if ('slug' in el) {
        const cardTitle = el.meta?.name ?? el.name;
        const cardImageURL = el.meta?.thumbs?.graphic_288x192 || '';
        const badge = getValidBadgesBySlug(games, el.slug, arenaBadges ?? []);
        const clickHandler = (ev: any) => {
          ev?.stopPropagation?.();
          AITracks?.genericTrack?.({
            eventName: 'gameCard',
            isNonInteraction: false,
            eventAction: AnalyticsEventAction.CLICK,
            customDimensions: {
              cardType: 'StandardCard',
              cardTitle: cardTitle,
              cardImageURL: cardImageURL,
              badge_text: badge.label
            }
          });
        };
        return <LocalizedLink prefetch='default' onClick={clickHandler} tabIndex={ETabIndexesOrderingLevels.AUTO} key={index} href={`/games/${gameAliasOrSlug}`} target={'_self'} role='group' aria-label={`Play ${el.name}`} aria-labelledby={`card_hover-block_title_${el.slug}`} aria-describedby={`card_hover-block_description_${el.slug}`}>
						<StandardCard size='medium' key={index} hoverBlock={{
            type: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock?.type,
            title: el.meta?.name ?? el.name,
            description: el.meta?.details,
            cta: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock?.buttonText ?? 'Play',
            background: arenaData?.layout?.styleOverride?.standardCard?.hoverBlock?.background
          }} background={el.meta?.thumbs?.graphic_288x192 || ''} loading={index > MAX_VISIBLE_GAMES ? 'lazy' : 'eager'} badge={badge} />
					</LocalizedLink>;
      }
      const adOptions: AdOptions = {
        'data-id': `divider-ad-${index}`,
        'product': 'arena',
        'dimensions': getDimensions([el.adType]) as AdOptions['dimensions']
      };
      return <div key={index} className={classNames(styles.adsDivider, {
        [styles.mobile]: mobileAdScreens.includes(screenSize as EScreenSize),
        [styles.desktop]: !mobileAdScreens.includes(screenSize as EScreenSize)
      })}>
					<Ad key={index} adOptions={adOptions} />
				</div>;
    });
  };
  return <div className='cardsGrid' style={{
    opacity: isPending ? 0.5 : 1
  }} data-sentry-component="StandardCardsGrid" data-sentry-source-file="index.tsx">
			{title && <div className={styles.titleContainer}>
					<span className={styles.title}>{title}</span>
					<a className={styles.seeAll} href={`/${rootCategorySlug}/${category}`}>
						See all
					</a>
				</div>}
			<div ref={gridRef} className={styles.results}>
				{isAdsDisabled !== null && dividedElements.map((pageData, index) => {
        const {
          page: elemPage,
          data
        } = pageData;
        return elemPage <= page ? renderPage(data) : null;
      })}
				<div id='sentinel' ref={sentinelRef} />
			</div>
		</div>;
};