import React from 'react';
import { LocalizedLink } from '@/components/Link';
import { pill, leftImg, rightImg } from './styles.css';
import { Icon } from '../Icon';
export enum EAriaCurrentEnum {
  Page = 'page',
  Step = 'step',
  Location = 'location',
  Date = 'date',
  Time = 'time',
}
export type AriaCurrentType = EAriaCurrentEnum | boolean | undefined;
export type PillProps = {
  href?: string;
  selected?: boolean;
  label: string;
  ariaCurrent?: AriaCurrentType;
  leftImg?: string;
  rightImg?: string;
  variant?: 'primary' | 'secondary';
  onClick?: () => void;
};
export const Pill = (props: PillProps) => {
  const pillClass = pill({
    variant: props.variant,
    isSelected: props.selected
  });
  return <div data-sentry-component="Pill" data-sentry-source-file="index.tsx">
			<LocalizedLink href={props.href ?? '#'} target='_self' className={pillClass} aria-current={props.selected ? props.ariaCurrent : undefined} onClick={props.onClick} data-sentry-element="LocalizedLink" data-sentry-source-file="index.tsx">
				{props.leftImg && <Icon name={props.leftImg} className={leftImg} />}
				{props.label}
				{props.rightImg && <Icon name={props.rightImg} className={rightImg} />}
			</LocalizedLink>
		</div>;
};