import { SVGProps } from 'react';
const href = '/assets/sprite.svg';
export function Icon({
  name,
  width = 24,
  height = 24,
  ...props
}: SVGProps<SVGSVGElement> & {
  name: string;
}) {
  return <svg width={width} height={height} {...props} data-sentry-element="svg" data-sentry-component="Icon" data-sentry-source-file="index.tsx">
			<use href={`${href}#${name}`} xlinkHref={`${href}#${name}`} data-sentry-element="use" data-sentry-source-file="index.tsx" />
		</svg>;
}