import { LocalizedLink } from '@/components/Link';
import classNames from 'classnames';
import { button } from './button.css';
type ButtonProps = {
  variant?: 'filled' | 'outline' | 'label' | 'disabled';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: React.ReactNode;
  focusable?: boolean;
  icon?: string;
  iconPosition?: 'before' | 'after';
};
export const Button = ({
  variant,
  className,
  href,
  onClick,
  children,
  focusable = true,
  size
}: ButtonProps) => {
  const buttonClass = button({
    variant,
    size
  });
  const attributes = {
    className: classNames(buttonClass, className),
    onClick,
    tabIndex: focusable === false ? -1 : 0
  };
  if (href) {
    return <LocalizedLink href={href} target='_self' {...attributes}>
				{children}
			</LocalizedLink>;
  }
  return <button type='button' {...attributes} data-sentry-component="Button" data-sentry-source-file="index.tsx">
			{children}
		</button>;
};