import classNames from 'classnames';
import { typography } from './typography.css';
type TypographyBaseProps<T extends React.ElementType> = {
  children: React.ReactNode;
  className?: string;
  size: 'large' | 'medium' | 'small';
  as?: T;
} & Omit<React.ComponentPropsWithoutRef<T>, 'className' | 'children' | 'size'>;
export const Display = <T extends React.ElementType = 'h1',>({
  children,
  size,
  className,
  as,
  ...props
}: TypographyBaseProps<T>) => {
  const Component = as || 'h1';
  return <Component className={classNames(typography({
    type: 'display',
    size
  }), className)} {...props} data-sentry-element="Component" data-sentry-component="Display" data-sentry-source-file="index.tsx">
			{children}
		</Component>;
};
export const Headline = <T extends React.ElementType = 'h2',>({
  children,
  size,
  className,
  as,
  ...props
}: TypographyBaseProps<T>) => {
  const Component = as || 'h2';
  return <Component className={classNames(typography({
    type: 'headline',
    size
  }), className)} {...props} data-sentry-element="Component" data-sentry-component="Headline" data-sentry-source-file="index.tsx">
			{children}
		</Component>;
};
export const Title = <T extends React.ElementType = 'h3',>({
  children,
  size,
  className,
  as,
  ...props
}: TypographyBaseProps<T>) => {
  const Component = as || 'h3';
  return <Component className={classNames(typography({
    type: 'title',
    size
  }), className)} {...props} data-sentry-element="Component" data-sentry-component="Title" data-sentry-source-file="index.tsx">
			{children}
		</Component>;
};
export const Label = <T extends React.ElementType = 'span',>({
  children,
  size,
  className,
  as,
  ...props
}: TypographyBaseProps<T>) => {
  const Component = as || 'span';
  return <Component className={classNames(typography({
    type: 'label',
    size
  }), className)} {...props} data-sentry-element="Component" data-sentry-component="Label" data-sentry-source-file="index.tsx">
			{children}
		</Component>;
};
export const Body = <T extends React.ElementType = 'p',>({
  children,
  size,
  className,
  as,
  ...props
}: TypographyBaseProps<T>) => {
  const Component = as || 'p';
  return <Component className={classNames(typography({
    type: 'body',
    size
  }), className)} {...props} data-sentry-element="Component" data-sentry-component="Body" data-sentry-source-file="index.tsx">
			{children}
		</Component>;
};