//@ts-ignore
import { AnalyticsEventAction } from '@arkadium/modules/dist/lib/Analytics/constants/AnalyticsContants';
import { WEB_COMPONENTS_NAMES } from '@/libs/utils';
import { PromoCardProps } from '@/components/PromoCard';
export type RawCarouselPromo = {
	__component: WEB_COMPONENTS_NAMES.CarouselPromo;
	carousels: TCarouselPromo[];
	id: number;
};

export type TButton = {
	url: string;
	label: string;
};

export type PromoSlide = {
	gradientOverlay: string;
	promos_library: Promo;
};

export type Promo = {
	id: number;
	title: string;
	button: TButton;
	image: {
		url: string;
	};
	text: string;
	background_color?: string;
	analyticId: string | null;
};

export type TCarouselPromo = {
	id: number;
	title: string;
	analyticId: string | null;
	slides: PromoSlide[];
	displayAd?: boolean;
	recentlyPlayed?: boolean;
	infiniteLoop?: boolean;
	index: number;
};

export type TOverriddenSlideAttributes = {
	attributes: PromoCardProps;
	handleClick?: (AITracks?: any) => void;
};

export const overrideSlideAttributes = (
	c: PromoSlide,
	AITracks: any,
	carouselId?: string | null,
): TOverriddenSlideAttributes | null => {
	// attributes mapping
	const attributesInitial: PromoCardProps = {
		title: c?.promos_library?.title || '',
		description: c?.promos_library?.text || '',
		overlay: c?.gradientOverlay || 'transparent',
		background: c?.promos_library?.image?.url || '',
		CTA: c?.promos_library?.button?.label || '',
	};

	const attributes: PromoCardProps = { ...attributesInitial } as PromoCardProps;

	//For Analytics - card click event
	const handleClick = (): void =>
		AITracks?.genericTrack?.({
			eventName: 'gameCard',
			isNonInteraction: false,
			eventAction: AnalyticsEventAction.CLICK,
			customDimensions: {
				cardType: 'PromoCard',
				cardTitle: c?.promos_library?.title ?? '',
				cardImageURL: c?.promos_library?.image?.url ?? '',
				carouselID: carouselId ?? '',
			},
		}) as void;

	return { attributes, handleClick };
};
