import React from 'react';
import { Button } from '../Button';
import { hexToRgb } from '@/uikit/helper_funcs/hexToRgb';
import Image from 'next/image';
import * as styles from './styles.css';
import { Display, Body, Label } from '@/components/Typography';
export type PromoCardProps = {
  title: string;
  description?: string;
  background: string;
  overlay?: string;
  CTA?: string;
};
export const PromoCard = (props: PromoCardProps) => {
  const title = props.title;
  const description = props.description;
  const backgroundImageDesktop = props.background || '';
  const color = props.overlay || '#000000';
  const rgbaColor = hexToRgb(color);
  const linkLabel = props.CTA || 'Play Today';
  //move to theme
  const overlayBackground = `linear-gradient(90deg, ${color} 47.19%, ${rgbaColor}, 0.60) 73.6%, ${rgbaColor}, 0.00) 84.16%)`;
  return <div className={styles.container} data-sentry-component="PromoCard" data-sentry-source-file="index.tsx">
			<Image src={backgroundImageDesktop} alt={title} loading='lazy' layout='fill' objectFit='cover' data-sentry-element="Image" data-sentry-source-file="index.tsx" />
			<div className={styles.overlay} style={{
      '--overlay-bg': overlayBackground
    } as React.CSSProperties} />
			<div className={styles.card}>
				<Display className={styles.title} size='large' data-sentry-element="Display" data-sentry-source-file="index.tsx">
					{title}
				</Display>
				<Body className={styles.description} size='large' data-sentry-element="Body" data-sentry-source-file="index.tsx">
					{description}
				</Body>
				<Button variant='filled' size='large' data-sentry-element="Button" data-sentry-source-file="index.tsx">
					<Label size='large' data-sentry-element="Label" data-sentry-source-file="index.tsx">{linkLabel}</Label>
				</Button>
			</div>
		</div>;
};