'use client';

import React, { useLayoutEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { useUnit } from 'effector-react';
import { $isSidebarHidden } from '@/app.model';
import classNames from 'classnames';
import { THorizontalPills, TPill } from './model';
import { Pill } from '../Pill';
import { useDeviceDetector } from '@/libs/utils/userAgentContext';
import ButtonPrev from '../../features/carousel/button-prev.svg';
import ButtonNext from '../../features/carousel/button-next.svg';
export type TProps = {
  config: THorizontalPills;
  isPillsFromPages?: boolean;
  isSearchPage?: boolean;
};
const HorizontalPills = ({
  config,
  isPillsFromPages = false,
  isSearchPage
}: TProps) => {
  const {
    pills,
    title,
    ariaCurrent
  } = config;
  const SCROLL_BEHAVIOR = 'smooth';
  const {
    isDesktop
  } = useDeviceDetector();
  const isDesktopDetected = isDesktop();
  const isSidebarHidden = useUnit($isSidebarHidden);

  // State to control arrow visibility
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  // Refs for the scroll container and arrow buttons
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // Function to update arrow visibility
  const updateArrows = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const {
        scrollLeft,
        scrollWidth,
        clientWidth
      } = container;

      // Show left arrow if not at the very start
      setShowLeftArrow(scrollLeft > 0);

      // Show right arrow if not at the very end
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 1);
    }
  };

  // Update arrows on mount, scroll, and resize
  useLayoutEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    // Initial arrow update
    updateArrows();

    // Event handlers
    const handleScroll = () => updateArrows();
    const handleResize = () => updateArrows();
    container.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Cleanup event listeners on unmount
    return () => {
      container?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Update arrows when the pills data changes
  useLayoutEffect(() => {
    updateArrows();
  }, [pills]);
  const handleRightArrowClick = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const containerWidth = container.clientWidth;
      const scrollAmount = containerWidth * 0.8; // Scroll by 80% of container width

      container.scrollBy({
        left: scrollAmount,
        behavior: SCROLL_BEHAVIOR
      });
    }
  };
  const handleLeftArrowClick = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const containerWidth = container.clientWidth;
      const scrollAmount = containerWidth * 0.8; // Scroll by 80% of container width

      container.scrollBy({
        left: -scrollAmount,
        behavior: SCROLL_BEHAVIOR
      });
    }
  };
  const getSelectedState = (pill: TPill) => {
    return pill.isActive;
  };
  const containerClass = classNames(styles.horizontalPill, 'horizontalPills');
  const wrapperClass = classNames(styles.horizontalPillWrapper, {
    [styles.pillsWithSidebar]: isDesktopDetected && !isSidebarHidden,
    [styles.pillsFromPages]: isPillsFromPages
  });
  return <div className={wrapperClass} data-sentry-component="HorizontalPills" data-sentry-source-file="index.tsx">
			{title && <h2 className={`text-20 ark-ui-title ${styles.title}`}>{title}</h2>}
			<div className={containerClass}>
				{isDesktopDetected && showLeftArrow && <button aria-label='Scroll Left' className={classNames(styles.buttonPrev, styles.arrowButton)} onClick={handleLeftArrowClick}>
						<ButtonPrev />
					</button>}
				<div className={styles.pillsContainer} ref={scrollContainerRef}>
					{pills.map((pill, index) => {
          return <Pill variant={isSearchPage ? 'secondary' : 'primary'} key={index} label={pill.label || ''} href={pill.href || '#'} leftImg={pill.leftImg} rightImg={pill.rightImg} selected={getSelectedState(pill)} ariaCurrent={ariaCurrent} onClick={() => config.onSelect?.(pill)} />;
        })}
				</div>
				{isDesktopDetected && showRightArrow && <button aria-label='Scroll Right' className={classNames(styles.buttonNext, styles.arrowButton)} onClick={handleRightArrowClick}>
						<ButtonNext />
					</button>}
			</div>
		</div>;
};
export default HorizontalPills;