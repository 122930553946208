import classNames from 'classnames';
import * as styles from './styles.css';
import Image from 'next/image';
import React from 'react';
import { hexToRgb } from '../../uikit_to_delete/helper_funcs/hexToRgb';
import { Button } from '../Button';
import { Label } from '../Typography';
import { Badge } from '../Badge';
import { ETabIndexesOrderingLevels } from '@/root/types/types';
import { Icon } from '../Icon';
interface StandardCardProps {
  background: string;
  size: 'small' | 'medium';
  hoverBlock?: {
    type?: 'partial' | 'full' | 'none' | null;
    title?: string;
    description?: string | null;
    cta?: string;
    background?: string | null;
  } | null;
  loading: 'eager' | 'lazy' | undefined;
  style?: any;
  badge?: {
    label: string;
    backgroundColor: string;
    labelColor: string;
  } | null;
  icon?: string;
}
export const StandardCard = ({
  size = 'medium',
  icon = 'play',
  ...props
}: StandardCardProps) => {
  const hoverBlockBg = props?.hoverBlock?.background ? `${hexToRgb(props?.hoverBlock?.background)}, 0.7)` : 'rgba(0, 0, 0, 0.7)';

  // Replace the parsing logic with a simple function to strip HTML tags
  const stripHtml = (html: string) => {
    return html.replace(/<[^>]*>/g, '');
  };
  const description = stripHtml(props?.hoverBlock?.description ?? '');
  const labelTitle = props?.hoverBlock?.cta || 'Play Now';
  return <div className={classNames(styles.card, !props.hoverBlock && styles.noOverlay, styles[size])} role='button' tabIndex={ETabIndexesOrderingLevels.NOINTERACTION} data-sentry-component="StandardCard" data-sentry-source-file="index.tsx">
			{props.background && <Image src={props.background} alt={props.hoverBlock?.title ?? 'Card Image'} className={styles.fullImage} fill />}
			{props.badge && props.badge.label && props.badge.backgroundColor && <Badge backgroundColor={props.badge.backgroundColor} labelColor={props.badge.labelColor} label={props.badge.label} />}
			{props.hoverBlock && <div className={styles.overlayWrapper}>
					<div className={styles.overlay} style={{
        backgroundColor: hoverBlockBg
      }}>
						<div className={styles.wrapper}>
							<p className={styles.title}>{props.hoverBlock.title}</p>
							<p className={styles.description}>{description}</p>
							<Button focusable={false} variant='filled' className={styles.button}>
								<Icon name={icon} />
								<Label size='large'>{labelTitle}</Label>
							</Button>
						</div>
					</div>
				</div>}
		</div>;
};